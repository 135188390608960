import React from 'react';
import PropTypes from 'prop-types';
import successStyles from './request-success-form.module.scss';
import Layout from '../../components/layout';
import Section from '../section';
import Footer from '../../components/footer';

const RequestSuccess = ({ node }) => (
  <Layout
    navigationBackgroundColor={node.navigationBackgroundColor}
    seoTitle={node.seo.seoTitle}
    seoDescription={node.seo.seoDescription.seoDescription}
    seoKeywords={node.seo.seoDescription.seoDescription}
  >
    <Section type="sidePaddingOnly" backgroundColor="#fed853">
      <div className={successStyles.container}>
        <h1 className={successStyles.title} style={{ color: node.titleColor }}>
          {node.title}
        </h1>
        <p className={successStyles.descriptionText} style={{ color: node.descriptionTextColor }}>
          {node.descriptionText}
        </p>
      </div>
    </Section>
    <Footer node={node.footer} />
  </Layout>
);

RequestSuccess.propTypes = {
  node: PropTypes.shape({
    footer: PropTypes.shape({
      id: PropTypes.string.isRequired,
      links: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          externalUrl: PropTypes.string.isRequired,
          slug: PropTypes.string.isRequired,
          displayName: PropTypes.string.isRequired,
        })
      ),
      seo: PropTypes.shape({
        seoTitle: PropTypes.string.isRequired,
        seoKeywords: PropTypes.shape({
          seoKeywords: PropTypes.string.isRequired,
        }),
        seoDescription: PropTypes.shape({
          seoDescription: PropTypes.string.isRequired,
        }),
      }),
      logo: PropTypes.shape({
        id: PropTypes.string.isRequired,
        file: PropTypes.shape({
          url: PropTypes.string.isRequired,
          fileName: PropTypes.string.isRequired,
          contentType: PropTypes.string.isRequired,
        }),
      }),
    }),
    title: PropTypes.string,
    navigationBackgroundColor: PropTypes.string.isRequired,
    titleColor: PropTypes.string.isRequired,
    descriptionText: PropTypes.string.isRequired,
    descriptionTextColor: PropTypes.string.isRequired,
  }).isRequired,
};

export default RequestSuccess;
