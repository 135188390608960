import React from 'react';
import head from 'lodash.head';
import { StaticQuery, graphql } from 'gatsby';
import RequestSucces from '../components/request-invitation-form/request-success';

const Success = () => (
  <StaticQuery
    query={graphql`
      query requestSuccessPage {
        allContentfulRequestSuccessPage {
          edges {
            node {
              title
              descriptionText
              navigationBackgroundColor
              titleColor
              descriptionTextColor
              seo {
                seoTitle
                seoKeywords {
                  seoKeywords
                }
                seoDescription {
                  seoDescription
                }
              }
              footer {
                id
                links {
                  id
                  externalUrl
                  slug
                  displayName
                }
                logo {
                  id
                  file {
                    url
                    fileName
                    contentType
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={({ allContentfulRequestSuccessPage: { edges } }) => {
      const { node } = head(edges);
      return <RequestSucces node={node} />;
    }}
  />
);

export default Success;
